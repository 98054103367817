.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.blue {
  color: #005eb8 !important;
}

.title {
  text-align: center;
  padding-top: 10px;
}

@media screen and (max-width: 601px) {
  .title {
    font-size: 21px !important;
  }
}

.coverblock {
  position: relative;
  text-align: center;
  color: white;
}

.coverimg {
  max-width: 100%;
  height: auto;
  max-height: 317px;
}

.covertag {
  position: absolute;
  top: 8px;
  right: 16px;
}

.ant-layout-header {
  background-color: #fff;
}

.ant-layout-footer {
  background-color: #122b40;
  color: #adb7bd;
  text-align: center;
}

.ant-card-meta-title {
  font-weight: 800;
}

.selected-book {
  /* border-color: green;
  border-width: 10px; */
}

.selectedtag-hidden {
  display: none;
}

.selectedtag {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: xxx-large;
}